@import '../../../Styles/settings/variables';

.password-checker {
  .icon {
    margin-right: $spaceMargin;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding: $smallerPadding 0;

      &.error {
        color: $error-color;
      }

      &.success {
        color: $success-color;
      }

      &.neutral {
        color: rgba($dark-color, 0.7);
      }

      &.hide {
        display: none;
      }
    }
  }
}