@import "src/Styles/settings/variables.scss";
@import "src/Styles/mixins/mixins.scss";

.user-data {
  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
    line-height: 1.5rem !important;
  }

  .name {
    font-weight: bold;
    font-size: $regular;
  }

  .document {
    font-weight: normal !important;
    font-size: $small;
  }
}

@media (max-width: 1023px) {
  button#btn-banking {
    padding: 8px;
    padding-left: 24px;
    line-height: 35px;
    font-size: 14px;
    text-align: left;
    border-radius: 0;
    border: 0;
    color: #666;
    transition: none;
    background: #fff!important;
    border-bottom: 1px solid #bbb!important;
  }

  button#btn-banking:hover,
  button#btn-banking:focus {
    color: #fff!important;
  }
}

.sidebar {

  &.horizontal {
    position: fixed;
    top: 0;
    left: 0;
    min-height: $menu-horizontal-height;
    width: 100%;
    background: $lighter-color;
    z-index: 100;
    overflow: hidden;
    @include boxShadow($darker-color, 0.1);

    @include mq(tablet-wide) {
      overflow: initial;
      min-height: 0;
      box-shadow: none;

      &.require-email-confirm {
        margin-top: $bigMargin;
      }
    }

    .container {
      display: flex;
    }

    .navbar-logo{
      float: left;
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;
      height: $menu-horizontal-height;
      width: 100%;

      @include mq('tablet-wide') {
        align-items: normal;
        flex-direction: column;
        width: 80px;
        margin-right: $smallMargin;
      }

      h1 {
        margin-top: $smallMargin;
      }

      img{
        max-height: 30px;
        max-width: 80px;
      }

      .burger {
        position: absolute;
        left: 0;
        margin-left: $margin;
        cursor: pointer;
        transition: all .2s cubic-bezier(0, 1.26, .8, 1.28);

        @include mq('tablet-wide') {
          display: none;
        }
      }
    }

    @include mq(tablet-wide, max) {
      .ct-menu {
        border-top: none;
      }
    }
  }

  .menu {
    &.submenu {
      margin: 0;
      padding: 0;
      li {
        border-bottom: none !important;
        height: 36px;
        margin: 0;
        padding: 0 $padding;
        border-radius: 0;

        &:hover {
          border-bottom: none;
          padding-top: 0;
          background: $darker-color--alpha-01;

          a {
            color: $lighter-color;
          }
        }

        &.open {
          background: $darker-color--alpha-01;

          .arrow-up {
            display: none;
          }
        }

        .open, a {
          color: $lighter-color;
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  &.vertical {
    height: 100vh;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 98;
    min-width: $menuWidth;
    max-width: $menuWidth;
    padding: 0;
    background: $lighter-color;
  }
}

.burger {
  &-button {
    position: fixed;
    top: 15px;
    left: 15px;
    z-index: 1100 !important;

    @include mq('tablet-wide') {
      display: none;
    }
  }

  &-menu {
    @include mq('tablet-wide') {
      display: none;
    }
  }
}

.bars {
  background: $darker-color;
}

.overlay-menu {
  z-index: 2000000 !important;

  @include mq(tablet-wide) {
    display: none;
  }
}

.bm-cross {
  -ms-filter: invert(1);
  filter: invert(1);
}

.bm-menu-wrap {
  left: 0;
  background-color: $lighter-color;
  overflow: scroll;
  z-index: 2000000 !important;

  @include mq(tablet-wide) {
    display: none;
  }

  .menu.horizontal {
    display: flex;
    border: none;
    box-shadow: none;
    padding-top: 0;
  }

  .header {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-end;
    color: $lighter-color;
    border-bottom: solid 1px;
    outline: none;
    padding: $padding;

    .balance {
      display: flex;
      flex-direction: column;
      margin-top: $margin;

      &-label {
        font-size: $smaller;
      }

      &-value {
        font-size: $bigger;
      }
    }

    .profile {
      &-name {
        margin-left: $smallMargin;
      }
    }

    @include mq('tablet-wide') {
      margin-top: 0;
    }
  }
}
