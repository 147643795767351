@import '../../../Styles/mixins/mixins';
@import '../../../Styles/settings/variables';

.account-modal {
  .buttons-container {
    display: flex;
    justify-content: flex-end;
  }
}


.account-container{
  legend{
    font-size: $regular !important;
    color: $dark-color;
  }

  .card-header {
    justify-content: space-between;

    .title {
      display: flex;
      align-items: center;

      .status-wrapper {
        padding: $smallerPadding $smallPadding;
        color: $lighter-color;
        font-weight: bold;
        border-radius: $big-box-radius;
        margin-left: $smallMargin;

        &.succeeded {
          background-color: $success-color;
        }
        &.deleted {
          background-color: $error-color;
        }
        &.pending {
          background-color: $warning-color;
          color: $dark-color;
        }
      }
    }

    .edit {
      cursor: pointer;
      .icon {
        margin-left: $middleMargin;
        margin-right: $spaceMargin;
      }
    }
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;
  }

  a{
    color: $action-color;
    font-size: $regular;
  }

  .container {
    padding: $smallPadding $bigPadding $bigPadding;
    background: $lighter-color;
  }
}


.change-password-modal {
  .password-checker {
    margin-top: $margin;
  }
}

.change-password-style {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: normal;
  align-items: normal;
  align-content: normal;
}

.change-password-validation{
  display: flex;
  flex: 0 1 auto;
  align-self: auto;
  order: 0;
  width: 50%;
}

.change-password-form{
  display: flex;
  flex: 0 1 auto;
  align-self: auto;
  order: 0;
  width: 45%;
  margin-left: 5px;
  padding-left: 10px;
  border-left: 1px solid #cecece;
}

@media (max-width: 576px) {
  .change-password-style {
    flex-direction: column-reverse !important;
    gap: 2rem;
  }
  .change-password-validation, .change-password-form {
    width: 100% !important;
    border-left: 0;
    padding-left: 0;
  }
}