@import "src/Styles/settings/variables";
@import 'src/Styles/mixins/mixins';

.billing-payment {
  .mobile-no-padding {
    padding: 0;
  }
  .common-box {
    padding: 50px;
  }
  .barcode {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: $bigPadding;
    &-form {
      flex-grow: unset;
      .input-barcode {
        width: 100%;
        border-radius: $box-radius;
        text-align: center;
        padding: 25px;
        height: 55px !important;
        font-size: $bigger !important;
      }

      .card-form {
        &-body {
          .form-group {
            margin-bottom: unset;
            label {
              margin-bottom: unset;
            }
          }
        }
        &-footer {
          .buttons-wrapper {
            margin-top: $margin;
            float: right;
            .btn {
              color: $lighter-color;
            }
          }
        }
      }
    }
  }

  .info {
    border-right: $lightBorder;
    padding-right: $bigPadding;

    &-barcode {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-right: $bigPadding;
    }

    &-payment {
      padding-left: $bigPadding;
    }
  }

  .title {
    font-size: $big;
  }

  .resume {
    .info {
      &-value {
        color: $dark-color;
      }

      &-label {
        color: rgba($dark-color, 0.82);
      }
    }
  }

  .total-amount-field {
    .form-control-static {
      font-size: $largest;
      line-height: 1.2;
    }

    .form-control {
      height: 55px !important;
      font-size: $large !important;
    }
  }

  .payment-buttons-container {
    .buttons-col {
      display: flex;
      justify-content: space-around;
    }
    justify-content: flex-end;
  }

  .line-separator {
    margin-top: $margin;
    margin-bottom: $margin;
    @include mq('tablet-wide') {
      margin: $bigMargin 0;
    }
  }

  .success-wrapper {
    @include flexBox(center, center);
    flex-direction: column;
    flex-basis: 100%;

    button {
      a {
        color: $lighter-color !important;

        &:hover {
          text-decoration: none;
        }
      }
    }

    h2 {
      font-weight: bold;
      margin: $margin 0;
      color: $dark-color;
      font-size: $big;
    }

    svg {
      width: 100px;
      height: 100px
    }

    .detail-link {
      margin-top: $middleMargin;
      font-weight: bold;
      text-decoration: none;
    }
    .mobile-detail-link {
      font-size: $small;
    }
  }
}

