@import "src/Styles/settings/variables";
@import "src/Styles/mixins/mixins";

.pos-pairing {
  padding: 60px;

  .images-container {
    text-align: center;
    padding: $bigPadding 0;

    @include mq(tablet) {
      border-right: $lightBorder;
    }
  }

  .pos-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @include mq(tablet) {
      text-align: left;
      padding: $bigPadding 0;
    }

    &.success-message-container {
      justify-content: space-between;

      .btn {
        margin: $margin 0;
      }
    }

    .form-group-token {
      margin-bottom: 0;
    }

    .token-label {
      color: rgba($dark-color, 0.7);
      display: none;

      @include mq(tablet) {
        display: block;
      }
    }

    .input-container {
      @include mq(tablet) {
        display: flex;
        flex: 1;
      }

      .pos-token-input {
        text-align: center;

        @include mq(tablet) {
          width: unset;
        }
      }

      .pair-pos-button {
        padding: 0.375rem 0.75rem;
        margin-top: $margin;

        @include mq(tablet) {
          margin-top: 0;
          margin-left: $margin;
        }
      }
    }
  }
}
