@import "src/Styles/settings/variables";
.modal-receipt {
  position: relative;
  label {
    display: inline-flex;
    margin: 0.5rem 10px;
    align-items: center;
    input {
      margin-right: 5px;
    }
  }
}
.sale-modal-body {
  font-size: $small;
  .label {
    background-color: #eeeeee;
    padding: 5px;
    color: #5e5e5e;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 20px;
  }
}

.sale-modal-footer {
  margin: 0 20px 20px 20px;
  height: 40px;
  display: flex;
  justify-content: flex-end;
}
.modal-receipt {
  p {
    margin: $smallMargin 0;
  }

  .info {
    font-size: $small;
  }

  .amount {
    font-weight: bold;
  }

  .data {
    margin: 0;
  }
}

