@import 'src/Styles/mixins/mixins';
@import 'src/Styles/settings/variables';

.overlay {
  z-index: 1200 !important;
}

.mobile-modal-overlay {
  padding: 0;
  background-color: $lighter-color;

  .mobile-modal {
    max-width: none;
    flex: none;
    border-radius: 0 !important;
    margin: 0;
    height: 100%;
    background: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    .header {
      h1 {
        font-size: $biggest;
      }
    }

    .btn {
      width: 100%;
      padding: $middlePadding $smallPadding;
      font-size: $bigger;
      font-weight: bold;
      margin: 0;
    }

    .footer {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: space-between;
      background-color: $lighter-color;
      padding: $smallPadding $padding $largePadding $padding;

      .btn {
        &:not(:last-of-type) {
          margin-bottom: $smallMargin;
        }
      }
    }
  }
}

.customModal {
  border-radius: 4px;
  padding: 30px !important;

  .header {
    @include flexBox(space-between, flex-start);
    margin-bottom: 20px;

    h1 {
      font-size: $bigger;
      font-weight: bold;
      margin: 0;
      color: $dark-color
    }
    .close-btn {
      font-weight: bold;
      cursor: pointer;

      svg {
        width: $mediumIconSize;
        height: $mediumIconSize;
      }
    }
  }
  .content {
    form {
      .form-group:last-child{
        margin-bottom: 0;
      }
    }

  }
  .footer {
    @include flexBox(flex-end, center);
    flex-wrap: wrap;
    padding: 0;

    :not(:last-child) {
        margin-bottom: 0;
        margin-right: $smallMargin;
    }
  }
}
