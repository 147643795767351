.mobile-footer {
  background-color: #efefef;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  p {
    margin: 0;
  }


  .ombudsman{
    margin-top: 15px;
    p {
      font-size: 9px;
    }
  }
}
