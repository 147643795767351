@import 'src/Styles/settings/variables';
@import 'src/Styles/mixins/mixins';

.wrapper {
  background-color: $lighter-color;
  padding: $padding;
}

.status-info {
  padding: $smallPadding $padding;
  -webkit-border-radius: $box-radius;
  -moz-border-radius: $box-radius;
  border-radius: $box-radius;
  margin-bottom: $smallMargin;
  &.enabled, &.active {
    background-color: rgba($success-color, 0.5);
  }

  &.pending {
    background-color: rgba($warning-color, 0.5);
  }
}

.status-icon {
  height: $iconSize;
  width: $iconSize;
  margin-right: $spaceMargin;

  @include mq('tablet') {
    margin-right: $smallMargin;
  }
}

.sent-documents-container {
  .common-box {
    .ReactTable {
      .rt-tbody {
        .rt-td {
          margin: auto;
          &:last-of-type {
            width: 140px !important;
            text-align: center;
          }
        }
        .rt-tr-group {
          .rt-tr {
            cursor: default;
          }
        }
      }
    }
  }
}

.documents-container {
  a[download] {
    cursor: pointer;
  }

  .categories {
    .category-container {
      padding: $padding 0;
      display: flex;
      justify-content: space-between;
      max-height: 200px;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }

      &:not(:last-child) {
        border-bottom: solid 1px rgba($dark-color, 0.7);
      }

      @include mq(desktop) {
        padding: $bigPadding 0 !important;
        border-top: solid 1px rgba($dark-color, 0.7) !important;
        border-bottom: none !important;
      }

      .info {
        display: flex;
        flex-direction: column;
        flex: 1;

        .content {
          display: flex;
          align-items: flex-end;
          height: 100%;
          overflow: hidden;

          @include mq(desktop) {
            display: block;
          }
          .documents {
            display: flex;
            flex-direction: column;
            color: rgba($dark-color, 0.7);
            padding-top: $margin;
            height: 100%;

            @include mq(desktop) {
              flex-direction: row;
              padding-top: $bigMargin;
            }

            .message {
              margin-bottom: 0;
              flex: 0.8;

              @include mq(desktop) {
                margin-right: $smallMargin;
              }
            }

            .list {
              height: 100%;
              overflow: hidden;
              margin-top: $margin;

              @include mq(desktop) {
                margin-top: 0;
              }

              ul {
                height: 100%;
                overflow-y: scroll;
                list-style: none;
                padding-left: 0;
                font-weight: bold;
                margin-bottom: 0;
              }
            }
          }
          .camera {
            padding: $smallPadding;
            background-color: rgba($dark-color, 0.1);
            -webkit-border-radius: $big-box-radius;
            -moz-border-radius: $big-box-radius;
            border-radius: $big-box-radius;
          }
        }
      }

      .badges-container {
        display: flex;
        justify-content: space-between;

        @include mq(desktop) {
          display: block;
        }

        .badge {
          color: $lighter-color;
          font-weight: bold;
          padding: $smallerPadding $smallPadding;
          font-size: $smaller;
          margin-left: $spaceMargin;

          &:not(:first-child):not(:last-child) {
            margin: 0 $spaceMargin;
          }
        }
      }

      .ct-icon{
        display: inline-block;
        width: 80px;
        border-radius: 50%;
        margin-bottom: 10px;
        padding: 20px;
        background: #fafafa;
        box-shadow: $shadow;
      }

      .icon {
        &-svg {
          width: 100%;
        }
      }

      .buttons-wrapper {
        @include flexBox(center, center);
        justify-content: flex-end;
        .btn {
          margin: $spaceMargin 0 $spaceMargin $smallMargin;
          &:focus {
            box-shadow: none;
          }

          &-submit {
            color: $lighter-color;
            background-color: $action-color;
            border-color: $action-color;
            &:hover:not([disabled]) {
              background-color: $darker-action-color;
            }
            &:disabled {
              background-color: $action-color;
              border-color: $action-color;
            }
          }
          &-cancel {
            color: $action-color;
            background-color: transparent;
            border-color: $action-color;
            &:hover:not([disabled]) {
              background-color: $action-color;
              color: $lighter-color;
            }
            &:disabled {
              background-color: $action-color;
              border-color: $action-color;
            }
          }
        }
      }
    }
    .ct-file-drop {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }

  .files-list {
    list-style: none;
    padding: 0;
    display: none;

    li {
      background-color: #f3f3f3;
      border-radius: $box-radius;
      padding: 0 $padding;
      margin: $margin 0;
    }

    p {
      margin-bottom: 0;
    }

    .status {
      font-weight: bold;
      &.pending, &.failed, &.success, &.approved, &.rejected {
        background-color: transparent;
        background-position: left center;
        background-repeat: no-repeat;
        background-size: 12px;
        border: 0;
        text-align: left;
        text-transform: none;
        width: auto;
      }
      &.pending {
        color: $warning-color;
      }
      &.failed, &.rejected {
        color: $error-color;
      }
      &.success, &.approved {
        color: $success-color;
      }
    }

    .archive-name, .status {
      display: inline-block;
    }

    .status {
      padding-right: $smallPadding;
    }

    .archive-name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      position: relative;
      top: 4px;
      color: $lighter-color !important;
      font-weight: bold;

      .status {
        min-width: 100px;

        &.pending, &.failed, &.success, &.approved, &.rejected {
          background-color: transparent;
          background-position: left center;
          background-repeat: no-repeat;
          background-size: 12px;
          border: 0;
          padding-left: $padding;
          text-align: left;
          text-transform: none;
          width: auto;
        }
      }
    }
  }

  .files-list-empty {
    font-size: $regular;
    font-weight: bold;
    color: $error-color ;
  }
}
