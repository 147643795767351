@import 'src/Styles/mixins/mixins';
@import 'src/Styles/settings/variables';

.btn-activate-plan {
  display: flex;
  justify-content: flex-end;
}

.top-title{
  margin-bottom: 0 !important;
}

.fees-box-panel {
  .box-header {
    margin-bottom: $bigMargin;
  }
}

.fees-wrapper {
  position: relative;
}

.fees-container {
  @include flexBox(flex-start, none);
  flex-wrap: wrap;
  margin: $margin 0;

  .active-plan{
    position: absolute;
    right: -$smallMargin;
    top: $smallMargin;
    padding: 0 $smallPadding;
    background: $success-color;
    color: $lighter-color;
    font-size: $small;
    font-weight: bold;
    box-shadow: $shadow;

    svg {
      margin-right: $spaceMargin;
    }

    &::after {
      content: '';
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-bottom: 8px solid $success-color;
      position: absolute;
      bottom: -8px;
      right: 0;
      transform: rotate(180deg);
    }
  }

  .plan-header {
    @include flexBox(space-between, center);

    .title {
      font-weight: bold;

      :nth-child(2) {
        margin-left: $margin;
      }
    }
    button {
      margin-right: 20px;
    }
  }

  .Collapsible {
    width: 100%;
    margin-bottom: 0px;
    box-shadow: $card-box-shadow;
    -webkit-border-radius: $box-radius;
    -moz-border-radius: $box-radius;
    border-radius: $box-radius;

    .common-box{
      padding: 0 !important;
      margin-bottom: 0;
      box-shadow: none;
      .card-body{
        padding: 0;
      }
    }
  }

  .rt-tr {
    cursor: default !important;
  }

  .Collapsible__contentInner {
    border: none;
    border-radius: 0 0 $box-radius $box-radius;
    background-color: $lighter-color;
    &.active {
      border-left: 0;
      border-bottom-left-radius: 0;
    }
  }

  .Collapsible__contentOuter {
    &.active {

    }
  }

  .Collapsible__trigger {
    cursor: pointer;
    border: none;
    display: block;
    font-weight: 400;
    text-decoration: none;
    position: relative;
    padding: $padding;
    background: $lighter-color;
    border-radius: $box-radius;
    color: $darker-color;
    transition: border-bottom-color 100ms linear;
    .icon {
      top: 32%;
      right: 1%;
    }
    .icon-opened {
      display: none;
    }
    &:after {
      display: none;
    }

    &.is-open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      &:after {
        transform: rotateZ(90deg);
      }

      &.active {
        border-bottom: none;
      }
      .icon-closed {
        display: none;
      }
      .icon-opened {
        display: inline;
      }
    }

    &.is-closed {
      border-bottom-left-radius: $box-radius;
      border-bottom-right-radius: $box-radius;

      &.active {
        border-radius: $box-radius;
      }
    }

    &.is-display {
      opacity: 0.5;
      background-color: grey;
    }
    .common-box .ReactTable .rt-thead .rt-th,
    .common-box .ReactTable .rt-thead .rt-td{
      padding: 0 5px;
    }
  }

  .icon-brand {
    height: 21px;
  }

  .line-table {
    height: 35px;
    padding-top: 10px;
  }

  .brand-container {
    height: 35px;
    display: flex;
    align-items: center;
  }

  .header {
    @include flexBox(space-between, center)
  }

  .payment-types-wrapper {
    padding: $smallPadding;
  }
}
