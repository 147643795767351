@import '../../../Styles/mixins/mixins';
@import '../../../Styles/settings/variables';



.success-wrapper {
  @include flexBox(center, center);
  flex-direction: column;
  flex-basis: 100%;

  button {
    a {
      color: $lighter-color !important;

      &:hover {
        text-decoration: none;
      }
    }
  }

  h2 {
    font-weight: normal;
    margin: $margin 0;
    color: $dark-color;
    font-size: $big;
  }

  svg {
    width: 100px;
    height: 100px
  }

  .detail-link {
    margin-top: 15px;
    font-weight: bold;
    text-decoration: none;
  }
}
