@import "src/Styles/mixins/mixins";
@import "src/Styles/settings/variables";

.clipboard-wrapper {
  .txt {
    cursor: pointer;
    padding: 2px 0;
    margin-right: $spaceMargin;

    &:hover {
      border-radius: 4px;
    }
  }
}

.badge {
  @include keyframes(fadeIn) {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  @include animation(fadeIn 1s);
}
