@import "src/Styles/settings/variables.scss";
@import "src/Styles/mixins/mixins.scss";

.resume-table-wrapper{
  .resume-table {
    box-shadow: none;
  }
}

.left-b{
  border-top: 1px solid rgba(#555, 0.7);
  padding: $bigPadding 0 0 0 !important;
  margin: $bigMargin 0 0 0;

  @include mq('tablet') {
    border-top: none;
    border-left: 1px solid rgba(#555, 0.7);
    padding: 0 $bigPadding !important;
    margin: 0;
  }

  .rt-th {
    font-size: $regular;
    color: $dark-color;
    opacity: 0.7;
    line-height: $bigger;
  }
}

.resume-fields{
  padding-left: 0 !important;
  .resume-item{
    margin-bottom: $margin;
    &:last-child{
      margin-bottom: 0;
    }
  }
  legend {
    font-size: $regular;
    color: $dark-color;
    opacity: 0.7;
    line-height: $small;
    margin: 0 0 $spaceMargin 0;
  }
  p{
    font-size: $bigger;
    line-height: $bigger;
    font-weight: bold;
    margin: 0;
  }
}
.resume-table{
  .rt-tbody .rt-tr-group{
    border: 0 none !important;
  }
  .rt-thead .rt-th{
    padding: 6px 0 !important;
  }
  .rt-tbody .rt-tr-group .rt-td{
    padding: 6px 0 !important;
    font-size: $regular;
    &:first-child{
      font-weight: bold;
    }
  }
  .rt-tbody .rt-tr-group:last-child .rt-td{
    padding-bottom: 0 !important;
  }

  .rt-thead{
    border: 0 none !important;
  }
  .rt-thead .rt-resizable-header-content{
    font-weight: normal;
    color: $dark-color;
  }
}
.resume-table {
  .rt-tbody .rt-tr-group .rt-td{
    cursor: default
  }
}
.ct-resume{
  padding: $padding $bigPadding 0;
  box-shadow: 0 -3px 2px #ddd;
  margin-left: -20px !important;
  margin-right: -20px !important;

  @include mq(phone-wide) {
    margin-left: -30px !important;
    margin-right: -30px !important;
  }
}
