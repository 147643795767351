@import '../../../Styles/settings/variables';
@import '../../../Styles/mixins/mixins';

.confirm-email-alert-container {
  text-align: center;
  padding: $smallerPadding;
  background-color: $light-warning-color;
  margin-top: 60px;
  margin-bottom: -60px;

  @include mq(tablet-wide) {
    margin: 0;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 200;
  }

  p {
    font-size: $regular;
    margin: 0;
  }

  span {
    font-size: $regular;
  }

  .send-mail-button {
    cursor: pointer;
    margin-left: $spaceMargin;
  }
}
