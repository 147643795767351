@import 'src/Styles/mixins/mixins';
@import 'src/Styles/settings/variables';

.receiving-policy {
  &-option {
    display: flex;

    .payout-label {
      margin-left: $smallMargin;

      .label {
        color: $dark-color;
        font-weight: bold;
      }
    }

    .selector {
      margin-top: 3px;
    }
  }
}
.receiving-policy-selector{
  flex: 1;
  display: flex;
  justify-content: flex-start;

  .receiving-policy-option:last-child {
    margin-left: $bigMargin;

    @include mq(tablet) {
      margin-left: 0;
    }
  }

  @include mq(tablet) {
    flex-direction: column;
    justify-content: center;
    margin-right: $bigMargin;
  }
}