@import "src/Styles/settings/variables";

.table-actions-container {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  .success {
    color: $success-color;
    font-weight: bold;
  }

  .check-icon {
    margin-left: $spaceMargin;
  }

  .action {
    cursor: pointer;
    font-weight: bold;
    margin: 0 $spaceMargin;
    background-color: rgba($dark-color, 0.1);
    padding: $smallerPadding $smallPadding;
    -webkit-border-radius: $box-radius;
    -moz-border-radius: $box-radius;
    border-radius: 8px;
  }
}

.status-info {
  padding: $smallPadding $padding;
  -webkit-border-radius: $box-radius;
  -moz-border-radius: $box-radius;
  border-radius: $box-radius;
  margin-bottom: $smallMargin;
  &.error {
    background-color: rgba($error-color, 0.5);
  }

}

.bank-accounts-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: $margin;
}

.bank-accounts-table {
  .rt-tr {
    cursor: default !important;

    .badge-warning {
      color: $lighter-color;
      padding: $smallerPadding $smallPadding;
      font-size: $regular;
    }
  }
}
