@import 'src/Styles/mixins/mixins';
@import 'src/Styles/settings/variables.scss';

.agenda {
    @include flexBox(space-between, normal);
    flex-wrap: wrap;
    position: relative;

    .loading {
        background-color: rgba(255, 255, 255, 0.9);
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1000;
        display: flex;
        justify-content: center;
    }

    &-header-container {
        display: flex;
        align-items: flex-end;
        height: 100px;
        position: relative;
        
        @media (max-width: 768px) {
            height:120px;
        }

        .btn {
            padding: $smallerPadding $padding;
            font-size: $regular;
            margin-right: $margin;
        }

        .title {
            font-size: $bigger;
            min-width: 140px;
            text-align: center;
        }

        .receivables-warning{
            max-width: 355px;
            position: absolute;
            right: 0;

            @media (max-width: 768px) {
                position: absolute;
                top: 0px;
                width: 100%;
                padding-left: 0;
                max-width: 100%;
            }

            h3{
                font-weight: bold;
                font-size: $small;
                margin-bottom:0;
            }

            p{
                font-size: $smaller;
            }
        }
    }

    &-arrow {
        width: 24px;
        height: 24px;
    }

    .calendar-wrapper {
        height: max-content;
    }

    .box-detail-calendar {
        background: $lighter-color;
        border-radius: $box-radius;
        box-shadow: $shadow;
        padding: $padding;
        font-size: $regular;
        margin-right: 15px;
        margin-left: 15px;
        margin-top: $margin;
        display: flex;

        @include mq(tablet) {
            margin: 0;
        }

        .box-content {
            display: flex;
            flex-direction: column;
            flex: 1;
            overflow: hidden;

            .title {
                font-weight: bold;
                font-size: $big;
            }
            .subtitle {
                opacity: 0.7;
                color: $dark-color;
                margin-bottom: 0;
            }
            .content {
                overflow-y: scroll;
                overflow-x: hidden;
                flex: 1;
                max-height: 400px;

                @include mq(tablet) {
                    display: flex;
                    align-items: center;
                    height: 1px;
                }
            }
            .footer {
                padding: $padding 0 0 0;
                display: flex;
                justify-content: space-between;
                font-weight: bold;
                font-size: $regular;
                border-top: solid 1px $dark-color;
            }
        }

        .transfers {
            font-size: $small;
            position: relative;
            width: 100%;
            height: 100%;

            .rt-table {
                height: 100%;
            }

            .transfer {
                border-bottom: 1px solid $dark-color;
                display: flex;
                justify-content: space-between;
                padding: $smallPadding 0;
                color: $dark-color;
                font-size: $regular;

                .title {
                    border: none;
                    display: inline-block;
                    color: $darker-color;
                }
                .details-link {
                    display: flex;
                    font-weight: bold;
                    text-decoration: none;
                    font-size: $small;

                    .icon {
                        width: $iconSize;
                        height: $iconSize;
                        margin-left: $spaceMargin;
                        margin-bottom: 3px;

                        .icon-svg {
                            path {
                                fill: $lighter-color !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .schedule {
        flex-grow: 1;
    }
}
