@import 'src/Styles/mixins/mixins';
@import 'src/Styles/settings/variables';

.policy-account-container{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @include mq(tablet) {
    flex-direction: row;
  }

  .no-account-box{
    background-color: $light-gray-color;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    .icon-container{
      margin-top: 40px;
      margin-bottom: 20px;

      svg{
        width:45px;
        height: 45px;
      }
      path {
        fill: $gray-color
      }
    }
  }

  .account-box{
    background-color: rgba($dark-color, 0.05) !important;
    border-left: 15px solid $success-color;
    border-radius: $box-radius;
    padding: $middlePadding $smallPadding;
    flex: 1;
    margin-top: $smallMargin;

    @include mq(tablet) {
      padding: $padding $bigPadding;
      margin-top: 0;
    }

    .account-box-title{
      color: $success-color;
      font-weight: bold;
      font-size: $big;
      margin-bottom: $smallMargin;
    }


    .bank-account{
      width: 100%;
    }

    &.full-width {
      .bank-account-data {
        @include mq(phone-wide) {
          .form-group {
            width: 30% !important;
            margin-left: 0 !important;
          }
        }
      }
    }
  }
}
