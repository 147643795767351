@import '../../../../Styles/settings/variables';

.new-sale-container {
  .empty-state-container {
    .empty-state-description {
      color: $dark-color;
      font-size: $big;
      max-width: 377px;
    }
  }
}
