$rccs-size: 200px;
$rccs-name-font-size: 12px;
$rccs-number-font-size: 12px;
$rccs-valid-font-size: 8px;
$rccs-expiry-font-size: 10px;
@import '~react-credit-cards/lib/styles';
@import '../../../Styles/mixins/mixins';
@import '../../../Styles/settings/variables';

.sale-confirm-container {
  justify-content: center;

  .info-header {
    font-weight: normal;
    font-size: $regular;
    margin-bottom: $smallMargin;
  }

  hr {
    margin: $margin 0;
  }

  button {
    a {
      color: $lighter-color !important;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .send-mail {
    @include flexBox(center, center);
    flex-direction: column;

    @include mq('tablet') {
      flex-direction: row;
    }

    width: 100%;

    .send-mail-text {
      text-align: center;
      font-size: $regular;
      margin-bottom: 0;

      @include mq('tablet') {
        text-align: start;
      }
    }
  }

  .send-credit-mail {
    @include flexBox(center, flex-start);
    flex-direction: column;

    .card-form-body {
      width: 245px;

      @include mq('tablet') {
        width: 280px;
      }

      @include mq('tablet-wide') {
        width: 400px;
      }
    }

    .form-group {
      flex-grow: 1;
    }

    &-text {
      font-size: $regular;
      margin-bottom: 0;
    }
  }

  .input-wrapper {
    @include flexBox(center, center);
    margin-top: $smallMargin;

    button {
      margin-bottom: $margin;
    }
  }

  .buttons-wrapper {
    @include flexBox(center, center);
  }
}

.switch-wrapper {
  @include flexBox(flex-start, center);

  margin: $margin 0;

  .icon-fix {
    margin-bottom: 4px;
  }

  h5 {
    font-size: $regular;
    margin-right: $spaceMargin;
    margin-bottom: 0;
  }

  .react-switch {
    margin-left: $smallMargin;
  }
}

.card-row {
  @include mq('tablet') {
    &.no-padding-y {
      padding-bottom: 0;
      padding-top: 0;
    }

    &.upper-credit-row {
      &:nth-child(-n + 3) {
        padding-top: 0;
      }
      &:nth-child(n + 3) {
        padding-bottom: 0;
      }
    }
  }

  flex-direction: column;
  justify-content: flex-start !important;

  .label {
    font-size: $small;
    color: rgba(85, 85, 85, 0.7);
    margin-bottom: $spaceMargin;
  }

  .value {
    font-size: $regular;
    color: $dark-color;

    &.amount {
      font-size: $bigger;
      font-weight: bold;
    }
  }
}

.customer-title {
  font-size: $regular;
}

.line-separator {
  width: 100%;
  border-radius: 3px;
  height: 0;
}

.no-seller-address-container {
  @include flexBox(center, center);
  flex-direction: column;
  margin: $largeMargin 0;

  .cant-issue-boleto {
    font-size: $biggest;
    font-weight: bold;
    margin-bottom: $smallMargin;
  }

  .address-needed {
    font-size: $big;
    margin-bottom: $bigMargin;
  }
}
