@import 'src/Styles/mixins/mixins';
@import "src/Styles/settings/variables.scss";

.sale-details {

  .info {
    justify-content: flex-start;
  }

  .sub-info {
    .resume-field {

      &-title {
        font-size: $small;
      }

      &-value {
        font-size: $small;
        font-weight: normal;

        img {
          margin-right: $spaceMargin;
        }
      }
    }
  }

  hr {
    margin: $margin 0;
  }

  .out-navigation {
    font-weight: bold;
  }

  .group{
    padding: 15px 30px;
  }

  .wrapper-content{
    background-color: $lighter-color;
    display: flex;
    flex-wrap: wrap;

    p {
      font-size: $regular;
    }

    .history-container {
      width: 100%;

      @include mq(tablet) {
        width: auto;
        margin-right: $bigPadding;
      }

      p {
        font-weight: bold;
      }
    }

    .receivables-container {
      width: 100%;

      @include mq(tablet) {
        width: auto;
        flex: 1;
      }

      p {
        font-weight: bold;
      }

      .Collapsible__contentOuter {
        padding: 0 $padding;
      }
    }
  }

  .transaction-id{
    .copy{
      cursor: pointer;
      font-size: $regular;
    }
  }

  .client-data{
    .row {
      margin-bottom: 5px;
    }
  }

  .line-divisor {
    margin: $margin 0;
  }

  .card-row {
    &:last-of-type {
      border-top: 1px solid $light-color;
    }
  }
  .card-info-row {
    span {
      text-align: right;
    }
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    h1 {
      margin-bottom: 0;
    }

    .btn-whitelabel {
    margin-left: 10px;
    background-color: transparent;
    padding: $spaceMargin $smallPadding;
    &:hover {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      }
    }
  }

  .Collapsible {
    margin-top: $margin;
    border: solid 1px rgba(85, 85, 85, 0.3);
    box-shadow: none;
  }

  .Collapsible__trigger {
    font-weight: bold;
    color: $dark-color;
    cursor: pointer;
  }

  .no-receivables {
    padding: $padding;
    background-color: $no-data-background-color;
    border-radius: $box-radius;
    color: $dark-color;
    font-size: $regular;
    font-weight: bold;
  }

  .receivables-amount {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .paid, .receivable {
      &:first-child {
        margin-right: $margin;
      }
      padding: $padding;
      flex: 1;
      background-color: $no-data-background-color;
      border-radius: $box-radius;
      color: $dark-color;

      .resume-field {
        &-title {
          font-size: $small;
        }
        &-value {
          font-size: $regular;
        }
      }
    }
  }
}
.modal-sale {
  .header {
    padding: 10px 10px 5px 20px;
    h5 {
      font-size: 16px;
      font-weight: 600;
    }
  }
  .body {
    padding: 15px;
    font-size: 12px;
    .label {
      background-color: #eeeeee;
      padding: 5px;
      color: #5e5e5e;
      width: 100%;
      border-radius: 5px;
      margin-bottom: 20px;
    }
    .form-group-modal {
      display: flex;
      margin: 10px 0;
      align-items: center;
      label {
        display: flex;
        align-items: center;
      }
      .form-control-modal {
        font-size: 12px;
        span {
          padding-left: 5px;
          min-width: 100px;
          display: inline-block;
        }
        input {
          border-radius: 5px;
          border: 1px solid #ccc;
          padding: 5px;
        }
      }
    }
  }
  .footer-modal {
    margin: 0 20px 20px 20px;
    height: 40px;
    display: flex;
    justify-content: flex-end;
    input {
      border-radius: 5px;
      border: 1px solid #ccc;
      padding: 5px;
    }
    .btn {
      padding: 5px;
      margin: 0 5px;
    }
  }
  .box {
    padding: $padding;
    margin: $margin auto;
    background-color: rgb(208, 226, 233);
    border: 1px solid  rgb(215, 226, 230);
    border-radius: 4px;

    .logo-mp {
      max-width: 200px;
      width: auto;
      text-align: center;
      margin: 0 auto;
    }

    .receipt {
      @include flexBox(center, center);

      pre {
        font-size: 8px;

        @include mq('phone-wide') {
          font-size: $smaller;
        }

        @include mq('tablet') {
          font-size: $small;
        }
      }
    }
  }
  label.reciept {
    width: auto;
    margin: 20px;
    display: block;
    input {
      border-radius: 5px;
      border: 1px solid #ccc;
      padding: 5px;
      width: 100%;
      margin: 0 auto;
    }
  }
}
