@import "src/Styles/settings/variables.scss";

.deposits-history {
  .rt-td {
    .money-label {
      text-align: right;

      .symbol {
        font-size: $small;
      }

      .amount {
        font-size: $regular;
      }
    }
  }

  .header-container {
    display: flex;
    justify-content: space-between;
  }
}
