@import '../../../Styles/mixins/mixins';
@import '../../../Styles/settings/variables';

.no-bank-account-container {
  @include flexBox(space-between, center)
}

.bank-account-modal {
  background-color: rgba(85, 85, 85, 0.15);
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 10px;
  p {
    margin-bottom: 0;
  }
}

.bank-accounts-container {
  background-color: $lighter-color;

  .info {
    padding: $smallPadding 0;

    .text {
      color: $dark-color;

      &.regular {
        font-size: $regular;
      }

      &.big {
        font-size: $big;
      }
    }
  }

  .bank-account-item {
    margin-bottom: $margin;

    .bank-account {


      max-height: 150px;
    }
  }

  .rt-td {
    .money-label {
      text-align: left;

      .symbol {
        font-size: $small;
      }

      .amount {
        font-size: $regular;
      }
    }
  }
}
