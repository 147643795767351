@import '../../../Styles/settings/variables';

.notfound-result{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: $padding;
  width: 100%;
  height: 100%;

  .content{
    width: 100%;
    font-size: $big;
    text-align: center;
  }
}
