@import "src/Styles/settings/variables";

.notFound {
    justify-content: center;
    align-items: center;
    padding-top: 60px;
    height: 100vh;
    background-color: white;

    .info1 {
        font-weight: bold;
        font-size: $biggest;
    }

    .error {
        font-size: $big;
        margin-bottom: $bigMargin;
    }

    .title {
        font-size: 60px;
    }

    .content {
        display: flex;
        justify-content: space-between;
    }

    .icon {
        opacity: 0.3;
    }
}
