@import '../../Styles/mixins/mixins';

.optin {
  position: relative;
  height: 100vh;
  width: 100%;

  @include mq(phablet, max) {
    padding: 20px;
  }

  @include mq(phablet) {
    padding: 50px;
  }

  h1 {
    text-align: center;

    @include mq(tablet) {
      font-size: 22px;
    }
  }

  > .contract {
    overflow-y: auto;
    max-height: 70vh;
  }

  &-footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mq(phablet, max) {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #fff;
      padding: 15px 25px;
    }

    @include mq(phablet) {
      margin: 40px 0;
    }

    .disclaimer {
      font-size: 12px;
      text-align: center;
      max-width: 486px;
      margin-bottom: 20px;
    }

    .actions {
      display: flex;
      flex-direction: column;
      :first-child {
        margin-bottom: 20px;
      }
    }
  }
}
