.react-joyride__tooltip{
  & > div > div{
    padding: 10px !important;
  }
}

.onboarding-text {

  text-align: left;

  .intro-image{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h1{
    margin-bottom: 10px;
  }


  hr {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .intro-title{
    padding-top: 20px;
    text-align: center;
    margin-bottom: 10px;
  }

  .intro-paragraph{
    margin: 10px;
    text-align: center;
  }

  .paragraph{
    text-align: left;
  }


  .buttons{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .jump-button{
      margin-right: auto;
      padding: 0px;
      font-size: 12px
    }

  }


  .intro-button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 15px;
  }

 .jump-intro-button{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 15px;


    button {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 12px;
    }

  }

}
