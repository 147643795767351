@import '../../../Styles/settings/variables';
@import '../../../Styles/mixins/mixins';

.exports-container {
  .loader-wrapper {
    width: 100%;
  }

  .btn-download {
    background-color: $btn-secondary-color;
    border: none;
  }

  .buttons-container {
    margin-bottom: $margin;

    .btn {
      &.btn-outline-primary {
        background-color: $lighter-color;
        border-color: rgba($dark-color, 0.3);
      }

      &:not(:last-of-type) {
        margin-right: $smallMargin;
      }
    }
  }

  .common-box {
    padding: 25px $middlePadding;

    @include mq(tablet-wide) {
      padding: $bigPadding;
    }
  }

  .export-card-container {
    padding: $padding;
    background-color: $lighter-color;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: $box-radius;
    margin-bottom: $middleMargin;

    .desktop {
      @include flexBox(space-between, stretch);

      .file-wrapper {
        width: 12%;
        @include flexBox(center, center);
        flex-direction: column;

        .file-icon {
          margin-bottom: $smallMargin;
        }

        p {
          font-size: $small;
          margin: 0;
          text-align: center;
        }
      }

      .status-and-action-wrapper {
        @include flexBox(space-between, center);
        flex-direction: column;
        width: 40%;

        .action-wrapper {
          @include flexBox(center, center);
          width: 100%;

          svg {
            margin-left: $spaceMargin;
          }

          .queued-export-disclaimer {
            font-size: $small;
          }
        }

        .icon {
          &.success {
            color: $success-color;
          }

          &.error {
            color: $error-color;
          }

          &.pending {
            color: $warning-color;
          }

          margin-right: $spaceMargin;
        }
      }
    }

    .details-wrapper {
      @include flexBox(space-between, center);
      margin-bottom: $smallMargin;

      strong {
        margin-right: $spaceMargin;
      }

      .success {
        color: $success-color;
      }

      .error {
        color: $error-color;
      }

      .pending {
        color: $warning-color;
      }

      @include mq('tablet-wide') {
        flex-direction: column;
        align-items: flex-start;
        width: 40%;
        margin-bottom: 0;

        p {
          font-weight: bold;
          margin-bottom: $spaceMargin;
        }

        span {
          font-size: $small;
        }
      }
    }

    .action-wrapper {
      border-radius: $box-radius;
      background-color: rgba($dark-color, 0.1);
      position: relative;
      overflow: hidden;

      &.success {
        cursor: pointer;
      }

      &.error {
        color: $error-color;
      }

      .export-progress-bar {
        -webkit-transition: width 500ms;
        -moz-transition: width 500ms;
        -ms-transition: width 500ms;
        -o-transition: width 500ms;
        transition: width 500ms;
        position: absolute;
        bottom: 0;
        left: 0;
        min-height: 4px;
        background-color: $success-color;
      }

      .export-action {
        @include flexBox(center, center);
        min-height: 40px;
        padding: 0 $padding;

        p {
          margin: 0;
          text-align: center;

          &.success {
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: bold;
          }
        }

        @include mq('tablet-wide') {
          min-height: 35px;
          padding: 0;
        }
      }
    }
  }

  .table {
    .extension-cell {
      text-align: center;
    }

    .filters-cell {
      color: $dark-color;
      p {
        margin-bottom: unset;
      }
    }

    .action-cell {
      text-align: right;
      padding-right: 0 !important;
      display: flex;
      justify-content: flex-end;

      .action-wrapper {
        display: flex;
        align-items: center;

        .size-text {
          margin-right: $smallMargin;
        }
      }

      .queued-export-disclaimer.error {
        width: unset;
      }
    }

    .rt-tr {
      cursor: auto !important;
    }

    .rt-tr-group {
      min-height: 90px;
    }
  }

  .export-list {
    padding: 0;
    list-style: none;

    .export-item {
      display: flex;
      justify-content: space-between;
      padding-bottom: $padding;
      border-bottom: solid 1px rgba($dark-color, 0.3);

      &:not(:first-child) {
        padding-top: $padding;
      }

      .text-container {
        flex: 1;
        p {
          font-size: $regular;
          margin-bottom: $spaceMargin;
        }
      }

      .download-button {
        flex: 0.1;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .btn-download {
          width: 40px;
          height: 40px;
        }

        .queued-export-disclaimer {
          width: 40px;
          height: 40px;
          padding: unset;

          .request-loading {
            margin-left: unset;
            width: 100%;
          }
        }
      }
    }
  }

  .export-action {
    .error {
      padding: $smallerPadding $smallPadding;
      background-color: rgba($error-color, 0.1);
      color: $error-color;
      border-radius: $box-radius;
      font-size: $small;
    }
  }
}
