@import "src/Styles/settings/variables.scss";
@import "src/Styles/mixins/mixins";

.menu.horizontal {
  width: 100%;

  &.submenu-opened {
    li {
      &:hover {
        border-bottom: none;
      }

      &.open,
      &.over {
        &.children {
          border-bottom: none;

          .arrow-up {
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid;
            position: absolute;
            bottom: 0;
          }
        }
      }
    }
  }

  &.open,
  &.over {
    display: flex;
    transform: translate3d(0vw, 0, 0);

    @include mq('tablet-wide') {
      display: block;
    }
  }

  ul {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
    height: 100%;
  }

  li {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style: none;
    cursor: pointer;
    padding: 0 $smallPadding;

    &.config {
      margin-left: auto;
      text-align: right;
      cursor: default;
    }

    a,
    span {
      color: $dark-color;
      line-height: $clickable-line-height;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:hover,
    &.open {
      text-decoration: none;
      box-sizing: border-box;

      a,
      span {
        text-decoration: none;
        font-weight: bold;
      }
    }

    &.open {
      .arrow-up {
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid $light-color;
        position: absolute;
        bottom: 0;
      }
    }

    &.over {
      .arrow-up {
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid $light-color;
        position: absolute;
        bottom: 0;
      }
    }

    .rc-menu-hidden {
      display: none;
    }

    .rc-menu-submenu-title {
      display: flex;
      align-items: center;
    }

    .close {
      display: none;
    }
  }
}