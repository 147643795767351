@import "src/Styles/settings/variables";
@import "src/Styles/mixins/mixins";

.complete-register-container {
  position: absolute;
  top: -60px;
  left: 0;
  right: 0;
  z-index: 250;
  opacity: 0;
  overflow: hidden;
  transition: top 3s ease;
  animation: fade 8s linear 1.25s 1;

  &.opened {
    opacity: 1;
    top: 150px;
  }

  .common-box-wrapper-children {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    @include mq(tablet) {
      flex-direction: row;
    }

    .btn-secondary {
      background-color: $lighter-color;
      color: $dark-color;
      border-color: transparent;
      font-weight: bold;
      font-size: $small;
    }
  }

  .warning-wrapper {
    display: flex;

    @include mq(tablet) {
      align-items: center;
    }
  }

  p {
    font-size: $small;
    text-align: center;
  }

  @include mq(tablet) {
    padding: 0 !important;
    box-shadow: 0 1px 2px 0 rgba($darker-color, 0.3);
    border-radius: $box-radius;

    p {
      font-size: $regular;
      margin-bottom: 0;
      text-align: left;
    }
  }

  .title-image {
    width: $largeIconSize;
    height: $largeIconSize;
    margin-bottom: $smallMargin;

    path {
      fill: $dark-color;
      opacity: 0.3;
    }

    &.completed {
      width: $largeIconSize;
      height: $largeIconSize;
      path {
        fill: $success-color;
        opacity: 1;
      }
    }

    &-container {
      text-align: center;
    }
  }

  .card {
    padding: $smallPadding;
    background-color: $warning-color;
    color: $dark-color;
    font-weight: bold;
    box-shadow: 0 2px 3px rgba($darker-color, 0.5);

    @include mq(tablet) {
      border-radius: $card-border-radius;
      padding: $smallPadding $padding;
    }

    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      position: relative;

      .info-text {
        text-align: center;
        margin-bottom: 0;
      }

      .line {
        padding: $padding 0;

        hr {
          border-top-width: 2px;
        }
      }
    }
  }

  .show-box-button {
    color: $lighter-color;
    margin-right: $smallMargin;
  }

  h2 {
    text-align: center;
    @include mq(tablet) {
      margin-bottom: $margin;
      text-align: left;
      width: 50%;
    }
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
