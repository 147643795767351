@import "src/Styles/settings/variables.scss";
@import "src/Styles/mixins/mixins";

.sale-extract {
    .header-container {
        display: flex;
        justify-content: space-between;
    }

    .extract-description {
        display: flex;
        flex-direction: column;

        .date {
            font-size: 11px;
        }
    }

    .rt-td {
        .money-label {
            display: flex;
            float: right;

            svg {
                width: 10px;
                height: 13px;
                position: relative;
                bottom: -1px;
            }

            .symbol {
                font-size: $small;
                position: relative;
                bottom: 5px;
                margin-top: 9px;
            }

            .amount {
                font-size: $big;
                font-weight: normal;
            }
        }
    }

    .box-resume {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: $margin;
        height: 112px;

        .box {
            border-radius: $box-radius;
            background-color: #ffffff;
            width: 32%;
            height: 120px;
            padding: $padding;
            position: relative;

            &.large {
                width: 48%;
            }

            &.row {
                margin-right: 0;
                margin-left: 0;
            }

            .left {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                border-right: 1px solid $darker-color--alpha-01;
                padding-left: 0;

                .money-label {
                    .amount {
                        font-size: 22px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
            }

            .right {
                display: flex;
                flex-direction: column;
                justify-content: center;

                span {
                    color: $dark-gray-font-color;
                    font-size: 12px;
                }

                span.amount {
                    font-weight: 900;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                .money-label {
                    .amount {
                        font-size: 10px;
                    }
                }
            }

            .header-box {
                display: flex;
                justify-content: space-between;

                small {
                    color: $dark-color;
                }

                a {
                    font-size: $regular;
                    font-weight: 600;
                    align-items: center;
                    display: flex;
                    margin-right: $spaceMargin;

                    svg {
                        width: 16px;
                        height: 16px;
                        margin-left: $spaceMargin;
                    }
                }
            }

            .footer-box {
                margin-top: 5px;

                .info {
                    color: $dark-color;
                }

                a {

                    &:visited,
                    &:active {
                        color: white;
                    }

                    &.btn {
                        border-radius: 3px;
                        height: 24px;
                        font-size: 14px;
                        line-height: 10px;

                        svg {
                            width: 16px;
                            height: 16px;
                            margin-left: $spaceMargin;
                        }
                    }

                    &.text-link {
                        font-size: 12px;
                        color: $dark-color;
                        text-decoration: none;
                        cursor: default;
                    }
                }
            }

            .value {
                font-size: $big;
                font-weight: 600;

                span {
                    font-size: 14px;
                    font-weight: 100;
                }
            }
        }


        &.mobile {
            flex-direction: column;
            height: 100%;
            margin-bottom: 0;

            .box {
                width: 100%;
                margin-bottom: 10px;

                .footer-box {
                    a {
                        &.btn {
                            height: 30px;
                            line-height: 16px;
                        }
                    }
                }
            }
        }
    }

    .common-box-wrapper-children {
        .card-body {
            padding: $padding;
        }

        .balance-header {
            padding: $smallPadding $padding;
            $sizeBorder: 5px;
            border-radius: $sizeBorder $sizeBorder 0px 0px;
            display: flex;
            justify-content: space-between;
            color: $lighter-color;

            .title {
                font-size: $small;
            }

            .value {
                font-size: $bigger;
                font-weight: bold;
            }

            .balance-content {
                &:not(:first-child):not(:last-child) {
                    margin-right: $margin;
                    margin-left: auto;
                }
            }

            .balances {
                @include flexBox(space-between, center);
            }
        }

        .value {
            &.negative {
                color: $error-color;
            }
        }

        .debit {
            color: $error-color;

            &::after {
                content: 'D';
                padding-left: $smallerPadding;
            }
        }

        .credit {
            color: $darker-color;

            &::after {
                content: 'C';
                padding-left: $smallerPadding;
            }
        }
    }

    .empty-state-container {
        .icon {
            svg {
                width: 70px;
                height: 70px;
            }
        }
    }
}

.buttons-container-banking-type {
    padding-left: 0 !important;
    gap: 0.25rem;
    margin-top: 10%;



    .btn {
        border-bottom: none;
        border-radius: 1rem 1rem 0 0;
        margin-right: 0.25rem;
    }

    @media (max-width: 500px) {
        margin-top: 25%;
    }
}