@import "src/Styles/settings/variables.scss";
@import "src/Styles/mixins/mixins";

.side-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 0;

  .submenu {
    background-color: $lighter-color;
  }

  ul {
    display: flex;
    flex-direction: column;
    background-color: $lighter-color;
    height: 100%;
  }

  li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: none;
    cursor: pointer;

    &.config{
      border-top: solid 1px $gray-color;
    }

    a, span {
      color: $dark-color;
      line-height: $clickable-line-height;
    }

    &:hover, &.open {
      text-decoration: none;
      box-sizing: border-box;

      a, span {
        text-decoration: none;
      }
    }

    &.open {
      a, span {
        color: $lighter-color;
        font-weight: bold;
      }
    }

    .rc-menu-hidden {
      display: none;
    }

    .rc-menu-submenu-title {
      display: flex;
      align-items: center;
    }

    &.open{
      ul {
        display: flex;
        flex-direction: column;
        z-index: 10;
        left: 0;
        top: $menu-horizontal-height;
        width: 100%;

        li {
          height: $menu-submenu-horizontal-height;
          margin: 0;
          padding: 0 $padding;
          border-bottom: none !important;

          &.open{
            display: flex;
            margin-bottom: 0;
            background: $darker-color--alpha-01;
            padding-top: 0;
          }

          &:hover{
            cursor: pointer;
            background: rgba(black, 0.1);
            padding-top: 0;
          }
          a {
            text-transform: capitalize;
          }
        }
      }
    }

    .close{
      display: none;
    }

    .open{
      display: block;
    }
  }
}

.rc-menu-submenu-active > .rc-menu-submenu-title, .rc-menu-item-active {
  background-color: transparent;
}

.rc-menu-item-active {
  a, span {
    color: $lighter-color !important;
    font-weight: bold;
  }
}

.rc-menu-submenu-active {
  span {
    color: $lighter-color !important;
    font-weight: bold;
  }
}

.rc-menu-sub.rc-menu-inline > .rc-menu-item {
  padding-left: 60px;
}
