@import "src/Styles/settings/variables";

.receiving-confirmation-modal {
  .header {
    margin-bottom: 0;
  }
  .subtitle {
    margin-top: $smallMargin;
    margin-bottom: $smallMargin;
  }
}