@import '../../../Styles/mixins/mixins';
@import '../../../Styles/settings/variables';

.deposit-detail {
  background-color: $lighter-color;

  .header {
    margin: $smallPadding 0;
  }

  .transaction-id {
    span {
      font-size: $small;
      cursor: pointer;
    }
  }

  .horizontal-card-info {
    @include flexBox(space-between, normal);
    flex-wrap: wrap;
  }

  .card-row {
    &:last-of-type {
      border-top: 1px solid #ccc;
    }
  }

  .recipient-wrapper {
    @include flexBox(flex-start, normal);
    flex-wrap: wrap;

    .recipient-info {
      flex: 1 1 50%;
    }
  }

  h3 {
    font-size: $big;
    font-weight: bold;
    padding: $smallPadding 0;
    border-bottom: 1px solid $light-color;
    margin-bottom: $margin;
  }
}

.deposit-details {
  .info {
    justify-content: flex-start;
  }

  .sub-info {
    .resume-field {

      &-title {
        font-size: $small;
      }

      &-value {
        font-size: $small;
        font-weight: normal;

        img {
          margin-right: $spaceMargin;
        }
      }
    }
  }

  hr {
    margin: $margin 0;
  }
}
