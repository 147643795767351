@import '../../../Styles/settings/variables.scss';
@import '../../../Styles/mixins/mixins.scss';

p {
  font-size: $regular;
}

.select-item {
  padding: 1px 0;

  h5 {
    font-size: $regular;
    margin-bottom: 3px;
    font-weight: bold;
  }

  p {
    font-size: $regular;
    margin-bottom: 0;
  }
}

.select-prepaid-card-item {
  @include mq('tablet') {
    @include flexBox(flex-start, center);

    h5 {
      margin-bottom: 0;
      margin-right: $margin;
    }
  }

  h5 {
    font-weight: bold;
    font-size: $regular;
  }

  p {
    margin-bottom: 0;
    font-size: $regular;
  }
}

.current-balance {
  .form-control {
    color: $dark-color !important;
    font-size: $bigger !important;
    padding: 0 !important;
  }
}

p {
  margin-bottom: 0;
}

.favored, .bank-account-wrapper {
  p {
    margin-bottom: 0;
  }
}

.amount {
  font-size: $bigger;
  color: $dark-color;
  margin-bottom: 0;
}



.seller-card {
  padding: 6px 13px;
  font-size: 14px;
  color: #4a4a4a;
  background-color: #f3f3f3 !important;

  p {
    margin: 0;
  }

  .holder-name {
    color: #000000
  }
}

.search-wrapper {
  margin-bottom: $margin;

  @include mq('tablet') {
    margin-top: 15px;
  }

  .search {
    cursor: pointer;
  }
}
