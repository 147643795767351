@import '../../../Styles/mixins/mixins';
@import '../../../Styles/settings/variables';

.stepper-container {
  display: flex;
  flex-wrap: wrap;

  .steps-wrapper {
    @include mq('tablet') {
      border-right: $lightBorder;
    }
  }
}

.content-wrapper {
  @include mq('tablet') {
    margin: $bigMargin;
  }

  margin-bottom: 0;

  &-title {
    margin-bottom: 54px;

    @include mq('tablet') {
      margin-bottom: $bigMargin;
    }
  }

  &-subtitle {
    margin: 0;
    color: $dark-color;
    opacity: 0.7;
  }

  h6 {
    color: $dark-color;
    font-weight: bold;
    margin-bottom: 0;
    font-size: $bigger;
    text-align: center;

    @include mq('tablet') {
      font-size: $big;
      text-align: left;
    }
  }
}


.horizontal-stepper {
  justify-content: center;
}

.label-vertical-root-container {
  margin-bottom: $bigMargin;
}

.step-label {
  font-size: $big !important;
  font-weight: bold !important;

  &.complete {
    color: $dark-color !important;
  }

  &.incomplete {
    color: rgba(85, 85, 85, 0.5) !important;
  }
}

.icon-container {
  padding-right: 0 !important;

  @include mq('tablet') {
    padding-right: $smallPadding !important;
  }

  svg {
    width: 1.25em;
    height: 1.25em;
    font-weight: bold;

    text {
      font-size: 11px;
    }
  }

  &.complete {
    svg {
      color: $success-color !important;
    }
  }

  &.incomplete {
    svg {
      color: rgba(85, 85, 85, 0.5) !important;
    }
  }
}
