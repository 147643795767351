@import "src/Styles/settings/variables.scss";
@import "src/Styles/mixins/mixins.scss";

.query-control{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  float: left;
  width: 100%;

  @include mq(phone-small) {
    justify-content: space-between;
  }

  @include mq(tablet-wide) {
    padding: $smallPadding 0;
  }

  .query-control-pagination {
    cursor: default;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include mq(phone-wide) {
      margin: 0 0 0 $spaceMargin;
      justify-content: center;
    }


    .arrow, .by, .number{
      float: left;
      margin: 4px;
    }

    .arrow{
      cursor: pointer;
      svg {
        width: 14px;
        height: 19px;
      }
    }
  }

  .query-control-total-results {
    font-size: $regular;
  }

  .query-control-dropbox{

    @include mq(tablet) {
      margin-right: $margin;
    }

    .label{
      float: left;
      font-size: $regular;
      margin-top: 3px;
      margin-right: 5px;
      margin-left: 5px;
    }

    .query-control-dropdown{
      float: left;
    }
  }
}
