@import 'src/Styles/mixins/mixins';
@import 'src/Styles/settings/variables';



.simulator-container {
  @include flexBox(space-between, normal);
  flex-wrap: wrap;
  margin: 0;

  .section {
    display: flex;
    flex-direction: column;

    > *:not(.title) {
      align-items: center;
      flex-grow: 1;
      width: 100%;
    }

    .title {
      font-size: $regular;
      font-weight: bold;
    }
  }

  > *:not(.divider) {
    flex: 1;
    padding: 0;
  }

  label {
    color: $dark-color;
    font-size: $regular;
    margin: 0;
    margin-bottom: 5px;
  }

  .type-check{
    input{
      position: static;
      margin: 0;
      margin-right: $smallMargin;
    }
    label{
      margin-right: $margin;
    }
  }

  .result-separator{
    margin: $margin 0;
    margin-top: $margin;
  }

  .btn-type {
    margin: $smallMargin 0 !important;
    border-radius: 0.25rem !important;
    max-width: 150px;
    max-height: 100px;

    &.selected {
      background-color: $action-color;
      color: $lighter-color;
    }
  }

  .card-info {
    min-width: 300px;

    .card-row {
      &:last-of-type {
        border-top: 1px solid #ccc;
      }
    }
  }
}

