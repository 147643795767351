@import '../../../Styles/mixins/mixins';

.footer {
  display: none;
  @include mq('tablet') {
    display: block;
    width: 100%;
    margin-top: 60px;
    border-top: 1px solid #dcdcdc;
    font-size: 1em;
    color: #555;

    .top {
      display: flex;
      justify-content: space-between;
      flex: 1;
      width: 100%;
      padding-top: 15px;
      padding-bottom: 15px;

      .divider {
        border: none;
        height: auto;
        border-radius: unset;
        width: 1px;
        margin: 0 25px;
        background-color: #d1d1d1;
      }

      ul {
        font-size: 12px;
        padding: 0;
        margin: 0;
        flex: 0.5;
        display: flex;
        justify-content: space-between;
        align-items: center;
        li {
          list-style: none;
        }
      }

      .info {
        flex: 0.3;
        p {
          margin: 0;
          font-size: 12px;

          strong {
            font-size: 14px;
          }
        }
      }

      .ombudsman{
        margin: 0 25px;
        flex: 0.2;
        p{
          font-size: 9px;
          
        }
      }
    }
  }
}
