@import "src/Styles/settings/variables.scss";

.sales-history {
  .rt-td {
    .money-label {
      display: flex;
      float: right;

      .symbol {
        font-size: $small;
        margin-top: 2px;
      }

      .amount {
        font-size: $regular;
      }
    }
  }

  .header-container {
    display: flex;
    justify-content: space-between;
  }
}
